<!--  -->
<template>
  <ul class="ads">
    <li class="adsList" v-for="(item, index) in adsList" :key="index" @click="goInfo(item)">
      <template v-if="index == 0">
        <div class="imglog">
          <img :src="item.cover_url" />
        </div>
        <div class="titleLog">
          <div class="left">
            <span class="type">{{ item.classification_name }}</span>
            <span class="title">{{ item.title }}</span>
          </div>
          <span class="time">{{ item.create_time }}</span>
        </div>
      </template>
      <template v-else>
        <div class="imgSmall">
          <img :src="item.cover_url" />
        </div>
        <div class="timeSmall">
          <span>{{ item.create_time }}</span>
        </div>
        <div class="titleSmall">
          <span class="type">{{ item.classification_name }}</span>
          <span class="title">{{ item.title }}</span>
        </div>
        <div class="infoSmall">
          <p>{{ item.content | detailize }}</p>
        </div>
      </template>
    </li>
  </ul>
</template>

<script>
import { recommends } from '../../api/wallpaper'
export default {
  name: '',
  components: {},
  props: {
    type: null,
    label: null,
  },
  data() {
    return {
      adsList: null,
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  filters: {
    detailize(self) {
      return self.slice(0, 38) + '...'
    },
  },
  methods: {
    goInfo(item) {
      if (this.type == 'label') {
        let data = this.$router.resolve({ path: '/realInfo_info', query: { id: item.id, name: this.label } })
        window.open(data.href, '_blank')
      } else {
        let data = this.$router.resolve({ path: '/realInfo_info', query: { id: item.id } })
        window.open(data.href, '_blank')
      }
    },
    async getList() {
      const res = await recommends()
      this.adsList = res.data.map(item => this.removeXMLTag(item))
    },
    removeXMLTag(article) {
      article && article.content ? (article.content = article.content.replace(/<.*?>/g, '')) : null
      return article
    },
  },
}
</script>

<style lang="scss" scoped>
.ads {
  letter-spacing: 0.8px;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  margin-top: 8px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 360px;
    top: 76px;
    background-color: black;
    opacity: 0.7;
  }

  .adsList {
    margin-top: 79px;
    margin-right: 26px;
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    flex-flow: wrap;
    height: 338px;
    width: 274px;
    cursor: pointer;

    &:first-child {
      width: 599px;
      &:hover {
        .titleLog {
          .type {
            background: rgba(64, 80, 255, 1);
          }
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .imglog {
      width: 599px;
      height: 338px;
      position: relative;
      border: 2px solid #ffffff;

      &::before {
        position: absolute;
        width: 115px;
        height: 79px;
        content: '';
        background: url('../../assets/img/realInfo/t.png') no-repeat;
        background-size: 100% 100%;
        left: -8px;
        top: -6px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .titleLog {
      position: absolute;
      left: 0;
      bottom: -2px;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      height: 46px;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
      }

      .type {
        margin-left: 20px;
        padding: 0 12px;
        background: rgba(64, 80, 255, 0.6);
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-right: 16px;
        height: 24px;
        display: flex;
        align-items: center;
        align-content: center;
        transition: 0.2s;
      }

      .title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.9);
        max-width: 284px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .time {
        margin-right: 18px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.6;
        letter-spacing: 0.5px;
      }
    }

    .imgSmall {
      width: 274px;
      height: 154px;
      border: 2px solid #ffffff;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .timeSmall {
      width: 100%;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 37px;
      margin-top: 12px;
      line-height: 1;
      position: relative;
      letter-spacing: 0.5px;

      &::before {
        width: 122px;
        height: 1px;
        position: absolute;
        right: 0;
        content: '';
        top: 6px;
        background: rgba(255, 255, 255, 0.4);
      }

      &::after {
        width: 10px;
        height: 2px;
        position: absolute;
        content: '';
        right: 0;
        top: 5px;
        background: rgba(255, 255, 255, 0.8);
      }
    }

    .titleSmall {
      width: 100%;
      margin-top: 27px;
      line-height: 1;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      align-content: center;
      align-items: center;
      margin-bottom: 13px;

      .type {
        padding: 0 12px;
        background: rgba(64, 80, 255, 0.6);
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-right: 6px;
        height: 24px;
        display: flex;
        align-items: center;
        align-content: center;
        transition: 0.2s;
      }

      .title {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: rgba(255, 255, 255, 0.8);
        transition: 0.2s;
      }
    }

    .infoSmall {
      height: 34px;
      width: 100%;
      overflow: hidden;

      p {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.4);
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        transition: 0.2s;
      }
    }

    &:hover {
      .titleSmall {
        .type {
          background: #4050ff;
        }

        .title {
          color: #fff;
        }
      }

      .infoSmall {
        p {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
</style>
