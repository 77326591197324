<!--  -->
<template>
  <ul class="realList">
    <li v-for="(item, index) in list" :key="index" @click="goInfo(item)">
      <div class="left">
        <img :src="item.cover_url" />
      </div>
      <div class="right">
        <div class="top">
          <div class="leftTitle">
            <span class="type">{{ item.classification_name }}</span>
            <span class="title main-title">{{ item.title }}</span>
          </div>
          <span class="time">{{ item.create_time }}</span>
        </div>
        <div class="center">
          <p class="details">{{ item.content }}</p>
        </div>
        <div class="bottom">
          <span v-for="(it, idx) in getLabels(item)" :key="idx" @click="goLabel(it)">{{ it }}</span>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    list: null,
    type: null,
    label: null,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    getLabels(item) {
      return item && item.label ? item.label.split(',') : []
    },
    goInfo(item) {
      if (this.label) {
        let data = this.$router.resolve({ path: '/realInfo_info', query: { id: item.id, label: this.label } })
        window.open(data.href, '_blank')
      } else {
        let data = this.$router.resolve({ path: '/realInfo_info', query: { id: item.id } })
        window.open(data.href, '_blank')
      }
    },
    //进入标签
    goLabel(label) {
      // this.$router.push({ path: "/realInfo_type", query: { id: item, name: item } })
      const data = this.$router.resolve({ path: '/realInfo_type', query: { label: label } })
      window.open(data.href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.realList {
  width: 1200px;
  letter-spacing: 0.8px;
  li {
    width: 100%;
    height: 220px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease;

    .left {
      width: 264px;
      display: flex;
      align-items: center;
      align-content: center;
      height: 100%;
      margin-right: 28px;
      img {
        width: 100%;
        height: 148px;
        object-fit: fill;
        border: 2px solid #ffffff;
      }
    }

    .right {
      width: 834px;
      height: 148px;

      &::before {
        content: '';
        position: absolute;
        right: 24px;
        bottom: 38px;
        width: 50px;
        height: 22px;
        opacity: 0;
        background-image: url(../../assets/img/realInfo/right.png);
        transition: 0.3s;
        z-index: 1;
      }
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        height: 47px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        position: relative;

        &::before {
          width: 11px;
          height: 2px;
          background: rgba(255, 255, 255, 0.9);
          content: '';
          position: absolute;
          bottom: -1px;
          right: 0;
        }

        .leftTitle {
          display: flex;
          align-items: center;

          margin-bottom: 11px;
          .type {
            padding: 0 12px;
            background: rgba(64, 80, 255, 0.6);
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin-right: 12px;
            height: 24px;
            display: flex;
            align-items: center;
            align-content: center;
          }

          .title {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.8);
            max-width: 500px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .time {
          margin-right: 12px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.3);
        }
      }

      .center {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        height: 34px;

        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.4);
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }

      .bottom {
        width: 100%;
        display: flex;

        span {
          padding: 0 10px;
          height: 24px;
          border: 1px solid rgba(255, 150, 47, 0.7);
          color: rgba(255, 150, 47, 0.7);
          margin-right: 14px;
          line-height: 24px;
          cursor: pointer;

          &:hover {
            border: 1px solid rgba(255, 150, 47, 1);
            color: rgba(255, 150, 47, 1);
            background: rgba(255, 150, 47, 0.4);
          }
        }
      }
    }

    &:hover {
      transform: translateX(24px);
      .title {
        color: white !important;
      }
      .right::before {
        opacity: 1;
      }
      &::before {
        position: absolute;
        content: '';
        width: 1920px;
        height: 222px;
        background: url('../../assets/img/realInfo/listBg.png') no-repeat center;
        background-size: 1920px 222px;
        top: 0;
        left: -356px;
        z-index: -1;
      }
    }
  }
}
</style>
