<!--  -->
<template>
  <div class="pagination">
    <el-pagination layout="prev, pager, next" :total="total" :page-size="pageSize" :current="current" @current-change="onCurrentChange" hide-on-single-page></el-pagination>
    <span v-if="total > pageSize" style="color: rgba(255, 255, 255, 0.8)">共{{ total }}条记录</span>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    total: {
      type: [Number],
      default: 0,
    },
    pageSize: {
      type: [Number, String],
      default: 4,
    },
    current: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    onCurrentChange(index) {
      this.$emit('onCurrentChange', index)
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  margin-top: 60px;

  .el-pagination {
    /deep/ button {
      background: transparent;
      width: 40px;
      height: 40px;

      &:disabled {
        cursor: default;
      }

      i {
        display: none;
      }

      &.btn-prev {
        background: url('../../assets/img/pagination/left_d.png') no-repeat;
        background-size: 100% 100%;
        margin-right: 8px;

        &:hover {
          background: url('../../assets/img/pagination/left_a.png') no-repeat;
          background-size: 100% 100%;
        }

        &:disabled:hover {
          background: url('../../assets/img/pagination/left_d.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      &.btn-next {
        background: url('../../assets/img/pagination/right_d.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 8px;

        &:hover {
          background: url('../../assets/img/pagination/right_a.png') no-repeat;
          background-size: 100% 100%;
        }

        &:disabled:hover {
          background: url('../../assets/img/pagination/right_d.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    /deep/ .el-pager {
      li {
        background: transparent;
        width: 40px;
        margin-right: 8px;
        height: 40px;
        font-size: 14px;
        padding: 0;
        color: rgba(255, 255, 255, 0.4);
        line-height: 40px;

        &.active {
          color: #fff;
          background: url('../../assets/img/pagination/page_d.png') no-repeat;
          background-size: 100% 100%;
        }

        &:hover {
          color: #fff;
          background: url('../../assets/img/pagination/page_a.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
